<template>
    <v-autocomplete 
            filled rounded dense  
            :items="areas"
            item-text="nombre"
            return-object
            multiple
            v-model="$store.state.workflows.areas_seleccionadas"
            prepend-icon="mdi-chart-timeline-variant"
            label="Selecciona las areas a las que será remitido el documento"
        >

    </v-autocomplete>
</template>
<script>
export default {
    props: {
        workflow: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            areas: [],
            //areas_seleccionadas: []
        }
    },
    watch: {
        workflow(val){
            console.log(val)
            this.$store.state.workflows.workflow = [...val]
        }
    },
    created(){
        console.log(this.workflow)
        this.$store.state.workflows.workflow = [...this.workflow]
        this.listar_areas()
    },
    methods: {
        async listar_areas(){
            try {
                let areas = await this.$store.dispatch('llamado_get',{
                    url: "https://audidoc.educandote.co/parse/classes/prueba01Sector",
                    tipo_header: "parse"
                })
                console.log(areas)
                this.areas = [...areas.data.results]

                this.areas.push({
                    nombre: 'Fin del proceso',
                    objectId: 'fin'
                })
               
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>