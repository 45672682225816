<template>
  <v-container>
    <v-row>
      <v-col>
        <v-sheet
          color=" indigo darken-4"
          dark
          rounded=""
          elevation="3"
          class="my-2"
        >
          <v-row>
            <v-col cols="1">
              <v-btn
                small
                text
                class="mt-1"
                @click="
                  $emit('volver');
                 $router.go(-1);
                "
              >
                <v-icon> mdi-arrow-left </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <div
                v-text="'Caso: ' + parametrosRuta.title"
                class="headline font-weight-light"
              ></div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-row>
          <v-col>
            <v-sheet
              width=""
              rounded
              height=""
              dark
              elevation="5"
              color="indigo darken-4"
            >
              <v-row justify="start">
                <v-col cols="12">
                  <div
                    v-text="'SGDA'"
                    class="headline font-weight-light ml-10"
                  ></div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet>
              <v-list rounded>
                <v-list-item-group color="primary" v-model="selectItem">
                  <v-list-item @click="window_acciones_disponibles = 0" link>
                    <v-list-item-icon>
                      <v-icon color="blue darken-4">mdi-book</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Carpeta publica</v-list-item-title>
                  </v-list-item>
                  <v-list-group
                    :value="false"
                    prepend-icon="mdi-account-circle"
                    @click="boton_responder_caso=false"
                    v-if=" parametrosRuta.data.estado == 'Aceptado'"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>
                        <div
                          v-text="'Mis acciones'"
                          class="subtitle font-weight-light"
                        ></div>
                      </v-list-item-title>
                    </template>
                    <v-list-item-group
                      color="primary"
                      
                    >
                      <v-list-item
                        link
                        @click="window_acciones_disponibles = 1;boton_responder_caso=false;boton_digitalizar_archivos=false"
                      >
                        
                          <v-list-item-title
                            v-text="'Archivos subidos'"
                            class="subtitle font-weight-light"
                          ></v-list-item-title>
                          <v-list-item-icon>
                            <v-icon color="blue">mdi-book-edit-outline</v-icon>
                          </v-list-item-icon>
                       
                      </v-list-item>
                      <v-list-item
                        v-if="$store.state.dataUser.permisos_caso.Digitalizar==true"
                        link
                        @click="boton_responder_caso=false;boton_digitalizar_archivos=true;window_acciones_disponibles=1"
                      >
                        
                          <v-list-item-title
                            v-text="'Digitalizar archivos'"
                            class="subtitle font-weight-light"
                          ></v-list-item-title>
                          <v-list-item-icon>
                            <v-icon color="blue darken-4">mdi-upload</v-icon>
                          </v-list-item-icon>
                        
                      </v-list-item>
                      <v-list-item
                          v-if="parametrosRuta.data.estado == 'Aceptado' && 
                           $store.state.dataUser.permisos_caso.Responder==true"
                          @click="boton_responder_caso=true; window_acciones_disponibles=1;boton_digitalizar_archivos=false"
                          link
                        >
                          <v-list-item-title v-text="'Responder caso '"
                            class="subtitle font-weight-light"></v-list-item-title>
                          <v-list-item-icon>
                            <v-icon color="blue darken-4">mdi-page-next-outline</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                  </v-list-group>
                  
                  <v-list-item
                    v-if="
                      (parametrosRuta.data.estado == 'Aceptado') &&
                      $store.state.dataUser.dataUser.rango == 'Supervisor'
                    "
                    @click="dialogo_delagar = true"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Delegar caso</v-list-item-title>
                  </v-list-item>
                  

                  <v-bottom-sheet
                    v-model="$store.state.dialogo_respuesta_caso_hijo"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                        v-if="parametrosRuta.data.estado == 'Pendiente' &&  $store.state.dataUser.permisos_caso.Aceptar==true"

                        @click="asignacion_variables('Aceptado')"
                        link
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Aceptar Caso </v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-sheet width="100%" height="100%">
                      <v-row>
                        <v-col>
                          <v-container>
                            <v-sheet
                              :color="color_dialogo"
                              rounded=""
                              elevation=""
                              class=""
                            >
                              <v-row>
                                <v-col>
                                  <div
                                    v-text="'Atención'"
                                    class="headline font-weight-light text-center"
                                    style="color:white"
                                  ></div>
                                </v-col>
                              </v-row>
                            </v-sheet>
                          </v-container>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-row>
                          <v-col cols="1">
                            <v-icon>mdi-warning</v-icon>
                          </v-col>
                          <v-col>
                            <div
                              v-text="mensaje"
                              class="text-center body-2"
                            ></div>
                          </v-col>
                        </v-row>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-container>
                            <v-textarea
                              outlined
                              dense
                              v-model="comentario"
                              label="Escribe un comentario acerca del caso que se te fue asignado."
                            />
                          </v-container>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" sm="2">
                          <v-btn
                          block
                            color="red"
                            text
                            @click="
                              $store.state.dialogo_respuesta_caso_hijo = false;
                              mensaje = '';
                            "
                            >Cancelar</v-btn
                          >
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col  cols="6" sm="2">
                          <v-btn
                            block
                            text
                            :disabled="comentario == ''"
                            @click="
                              $emit('responder', objeto_respuesta());
                              $store.state.load = true;
                              $store.state.dialogo_respuesta_caso_hijo = false;
                            "
                            :color="color_dialogo"
                            >Confirmar</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-bottom-sheet>

                  <v-list-item
                    v-if="parametrosRuta.data.estado == 'Pendiente' &&  $store.state.dataUser.permisos_caso.Rechazar==true"
                    @click="asignacion_variables('Rechazado')"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Rechazar caso</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.dataUser.dataUser.rango == 'Supervisor' 
                    && $store.state.dataUser.permisos_caso.Cancelar==true"
                    @click="asignacion_variables('Cancelado')"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon color="red darken-4" >mdi-close</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title> Cancelar caso </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical inset></v-divider>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col>
            <v-sheet
              color="indigo darken-4"
              dark
              elevation=""
              rounded=""
              class=""
            >
              <v-row>
                <v-col cols="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        text
                        @click="barra_herramientas = !barra_herramientas"
                        fab
                        class="ml-5 hide-only-sm"
                      >
                        <v-icon>mdi-view-stream</v-icon>
                      </v-btn>
                    </template>
                    <span>Opciones</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <div
                   
                    class="headline font-weight-light text-center mr-15 mt-1"
                  >
                  Información y archivos
                  </div>
                </v-col>
              </v-row>
               
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet color="" class="mt-2" elevation="">
              <v-row class="">
                <v-col>
                   <v-window v-model="window_acciones_disponibles" class="">
                    <v-window-item>
                      <v-toolbar dark color="indigo darken-4">
                        <v-toolbar-title>
                           Carpeta publica
                        </v-toolbar-title>
                      </v-toolbar>
                     
                       <v-tabs class="mt-3" grow>
                              <v-tab>
                                <v-icon left>
                                  mdi-upload
                                </v-icon>
                                Meta data
                              </v-tab>
                              <v-tab>
                                <v-icon left>
                                  mdi-book
                                </v-icon>
                                 Formularios
                              </v-tab>
                              <v-tab>
                                <v-icon left>
                                  mdi-upload
                                </v-icon>
                                 Archivos
                              </v-tab>
                              <v-tab-item>
                                 <v-sheet>
                                    <v-container>
                                      <v-row>
                                        <v-col>
                                          <v-sheet>
                                            <v-row>
                                              <v-col>
                                                <div
                                                  v-text="'Informacion importante del caso'"
                                                  class="header font-weight-bold text-center"
                                                ></div>
                                              </v-col>
                                            </v-row>
                                          </v-sheet>
                                        </v-col>
                                      </v-row>
                                    
                                      <v-row >
                                        <v-col class="mx-auto"
                                              v-for="(item, i) in inputs"
                                              :key="i" cols="12" sm="4" md="4"  >
                                          <v-text-field
                                            prepend-icon="mdi-book"
                                            :label="item.input"
                                            filled
                                            dense
                                            rounded
                                            readonly
                                            class=""
                                            v-model="item.value"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row class="mx-5">
                                        <v-col>
                                          <v-row>
                                            <v-expansion-panels>
                                              <v-expansion-panel>
                                                <v-expansion-panel-header disable-icon-rotate>
                                                  Datos del radicado
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                      <v-col>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-file-document-multiple-outline</v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Folios'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data
                                                                    .datos_del_radicado.informacion.folios
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-text-box-search-outline
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Asunto'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_del_radicado.informacion.asunto
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          
                                                      </v-container>
                                                      </v-col>
                                                      <v-col>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-file-document-multiple-outline
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Anexos'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_del_radicado.informacion.anexos
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-text-box-search-outline
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Descripcion'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_del_radicado.informacion.descripcion
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                      </v-col>
                                                      
                                                    </v-row>
                                                </v-expansion-panel-content>
                                              </v-expansion-panel>
                                            </v-expansion-panels>
                                          </v-row>
                                          
                                        </v-col>
                                      </v-row>
                                      <v-row class="mx-5">
                                        <v-col>
                                          <v-row>
                                            <v-expansion-panels>
                                              <v-expansion-panel>
                                                <v-expansion-panel-header disable-icon-rotate>
                                                  Datos de la persona remitente
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                      <v-col>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-account</v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Nombres'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data
                                                                    .datos_personales_remitente.nombre
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-phone
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Telefono'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_personales_remitente.telefono
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-domain
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Empresa'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_personales_remitente.empresa
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          
                                                      </v-container>
                                                      </v-col>
                                                      <v-col>
                                                        <v-container>
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-file-document-multiple-outline
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  parametrosRuta.data.datos_personales_remitente.tipo_documento.nombre
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_personales_remitente.numero_documento
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                          
                                                          <v-row>
                                                            <v-col cols="1">
                                                              <v-icon
                                                                >mdi-email
                                                                </v-icon
                                                              >
                                                            </v-col>
                                                            <v-col>
                                                              <div
                                                                class="title font-weight-bold"
                                                                v-text="
                                                                  'Correo'
                                                                "
                                                              ></div>
                                                              <div
                                                                v-text="
                                                                  parametrosRuta.data.datos_personales_remitente.correo
                                                                "
                                                                class="subtitle font-weight-light text-left ml-5"
                                                              ></div>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                      </v-col>
                                                      
                                                    </v-row>
                                                </v-expansion-panel-content>
                                              </v-expansion-panel>
                                            </v-expansion-panels>
                                          </v-row>
                                          
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-sheet>

                              </v-tab-item>
                              <v-tab-item>
                                <v-row>
                                  <v-col v-if="formularios_carpeta_publica.length>0">
                                     <v-window  v-model="window_formularios_carpeta_publica">
                                        <v-row>
                                          <v-col>
                                            <v-btn color="red" text block @click="window_formularios_carpeta_publica--" > Anterior </v-btn>
                                          </v-col>
                                          <v-col>
                                            <v-btn color="indigo" text block @click="window_formularios_carpeta_publica--" > Siguiente </v-btn>
                                          </v-col>
                                        </v-row>
                                        
                                        <v-window-item class="mt-4" v-for="(form,z) in formularios_carpeta_publica" :key="z">
                                          <v-toolbar dark color="indigo darken-4">
                                            <v-toolbar-title>
                                              {{form.nombre}}
                                            </v-toolbar-title>
                                          </v-toolbar>
                                          

                                          <v-row>
                                            <v-col class="mx-auto" cols="12" sm="4" md="3" v-for="(input,x)  in form.parametros" :key="x">
                                              <v-text-field filled dense rounded :label="input.name" readonly v-model="input.value"></v-text-field>
                                            </v-col>
                                          </v-row>
                                        </v-window-item>
                                      </v-window>
                                  </v-col>
                                  <v-col v-else>
                                    <v-row>
                                        <v-col>
                                          <div
                                            v-text="'No hay formularios subidos'"
                                            class="header font-weight-light text-center"
                                          ></div>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col>
                                          <v-img
                                            contain
                                            class="mx-auto"
                                            width="700"
                                            :src="require('../assets/notFound.png')"
                                          ></v-img>
                                        </v-col>
                                      </v-row>
                                  </v-col>
                                </v-row>
                               

                              </v-tab-item>
                              <v-tab-item>
                                <v-row>
                                  <v-col>
                                    <v-window v-model="window_docs">
                                      <v-container>
                                        <v-row>
                                          <v-col v-if="archivos.length > 0">
                                            <v-row>
                                              <v-col>
                                                <v-btn
                                                  text
                                                  color="red"
                                                  block
                                                  
                                                  @click="window_docs--"
                                                  >Anterior</v-btn
                                                >
                                              </v-col>
                                             
                                              <v-col>
                                                <v-btn
                                                  text
                                                  color="indigo"
                                                  block
                                                  @click="window_docs++"
                                                  >siguiente</v-btn
                                                >
                                              </v-col>
                                            </v-row>

                                            <v-row>
                                              <v-col>
                                                <v-window-item
                                                  v-for="(archivo, j) in archivos"
                                                  :key="j"
                                                >
                                                  <v-sheet>
                                                    <v-container>
                                                      <v-row
                                                        align="center"
                                                        justify="center"
                                                      >
                                                        <v-col>
                                                          <Archivos
                                                            :mostrar_carpeta_publica="true"
                                                          
                                                            :archivo="archivo"
                                                            :objId_caso="
                                                              parametrosRuta.data.objectId
                                                            "
                                                            :width="800"
                                                            :height="500"
                                                        /></v-col>
                                                        
                                                      </v-row>
                                                    </v-container>
                                                  </v-sheet>
                                                </v-window-item>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col v-else>
                                            <v-row>
                                              <v-col>
                                                <div
                                                  v-text="'No hay archivos subidos'"
                                                  class="header font-weight-light text-center"
                                                ></div>
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col>
                                                <v-img
                                                  contain
                                                  class="mx-auto"
                                                  width="700"
                                                  :src="require('../assets/notFound.png')"
                                                ></v-img>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-window>
                                  </v-col>
                                </v-row>

                              </v-tab-item>
                        </v-tabs>
                     
                    </v-window-item>
                    
                    <v-window-item>
                      <v-toolbar dark color="indigo darken-4">
                        <v-toolbar-title>
                          Mis acciones
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                           <v-btn class="ma-3" color="white" style="color:blue" 
                            dark @click="validar_digitalizar" 
                            v-if="boton_digitalizar_archivos"> Digitalizar 
                            <v-icon class="mx-3">mdi-upload</v-icon> 
                           </v-btn>
                           <v-btn class="ma-3" 
                            color="white" 
                            style="color:blue" 
                            dark 
                            @click="definir_tipo_respuesta(parametrosRuta.data)" 
                            v-if="boton_responder_caso"> Enviar respuesta 
                            <v-icon class="mx-3">mdi-page-next-outline</v-icon> 
                           </v-btn>
                           
                           
                           <template v-slot:extension>
                             <v-badge
                                bordered
                                color="error"
                                :content="$store.state.archivos_seleccionados.length"
                                :value="$store.state.archivos_seleccionados.length"
                                overlap
                              >
                                <v-btn
                                  

                                  color="blue"
                                  depressed
                                  small
                                  @click="dialogo_ver_archivos_seleccionados=true"
                                >
                                  <v-icon> mdi-book </v-icon>
                                  Archivos seleccionados
                                  
                                </v-btn>
                              </v-badge>
                             
                            </template>
                      </v-toolbar>
                      <v-row>
                        <v-col>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header disable-icon-rotate>
                              Sube archivos desde tu equipo
                              <template v-slot:actions>
                                <v-icon color="error">
                                  mdi-upload
                                </v-icon>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <Drop   v-on:info="archivos_seleccionados"   :permitir="'allFields'"  />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-window  class="mt-4" v-model="window2">
                          <v-tabs grow>
                              <v-tab>
                                <v-icon left>
                                  mdi-upload
                                </v-icon>
                                Archivivos
                              </v-tab>
                              <v-tab>
                                <v-icon left>
                                  mdi-book
                                </v-icon>
                                 Formularios
                              </v-tab>
                              <v-tab-item>
                                  <v-container>     
                                      <v-row>
                                          <v-col v-if="datos_adjuntos.length > 0">
                                            <v-row>
                                              <v-col cols="2">
                                                <v-btn
                                                  text
                                                  color="blue"
                                                  @click="window2--"
                                                  >Atras</v-btn
                                                ></v-col
                                              >
                                              <v-spacer></v-spacer>
                                              <v-col cols="2">
                                                <v-btn
                                                  text
                                                  color="blue"
                                                  @click="window2++"
                                                  >Siguiente</v-btn
                                                >
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col>
                                                <v-window v-model="window2">
                                                  <v-window-item v-for="(archivo, k) in datos_adjuntos" :key="k"  >
                                                    <v-sheet
                                                      class="mx-auto"
                                                      max-width="75%"
                                                      color=" "
                                                      elevation=""
                                                    >
                                                      <v-container>
                                                        <v-row>
                                                          <v-col>
                                                           
                                                            <Archivos
                                                              :mostrar_carpeta_publica="false"
                                                              :archivo="archivo"
                                                              :objId_caso="parametrosRuta.data.objectId"
                                                              :width="800"
                                                              :height="500"
                                                            />
                                                          </v-col>
                                                        </v-row>
                                                      </v-container>
                                                    </v-sheet>
                                                  </v-window-item>
                                                </v-window>
                                                
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col v-else>
                                            <v-row>
                                              <v-col>
                                                <div
                                                  v-text="'No hay archivos subidos'"
                                                  class="header font-weight-light text-center"
                                                ></div>
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col>
                                                <v-img
                                                  contain
                                                  width="700"
                                                  class="mx-auto"
                                                  :src="require('../assets/notFound.png')"
                                                ></v-img>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                </v-container>
                              </v-tab-item>
                                <!-- Listado de formularios enviados por los empleados a este supervisor-->
                              <v-tab-item v-if="$store.state.dataUser.dataUser.rango=='Supervisor'">
                                <v-window v-model="window_formularios" class="" v-if="formularios_supervisor.length>0">
                                      <v-row>
                                        <v-col cols="6">
                                          <v-btn block text color="red" @click="window_formularios--"> Anterior </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                          <v-btn block text color="indigo" @click="window_formularios++"> Siguiente </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-window-item v-for="(formulario,m) in formularios_supervisor" :key=m>
                                          <v-card>
                                            <v-card-text>
                                              
                                              <v-form>
                                                <v-row>
                                                  <v-col>
                                                      <h3 style="text-align:center"> Empleado: {{formulario.nombre_empleado}} </h3>
                                                  </v-col>
                                                
                                                  <v-col class="d-flex justify-center">
                                                    <v-tooltip class="d-flex justify-center " bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-btn
                                                            small
                                                            text
                                                            color="green darken-4"
                                                            fab
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="seleccionar(formulario,m)"
                                                          >
                                                            <v-icon>mdi-check</v-icon>
                                                          </v-btn>
                                                        </template>
                                                        <span>Seleccionar</span>
                                                      </v-tooltip>
                                                  </v-col>
                                                </v-row>
                                            
                                                  <v-row>
                                                    <v-col class="mx-auto" cols="12" sm="4" md="3" v-for="(input,n) in formulario.formulario.parametros" :key="n">
                                                      <v-text-field readonly rounded filled dense :label="input.name" v-model="input.value" ></v-text-field>
                                                    </v-col>
                                                  </v-row>
                                                  <v-row>
                                                    <!---
                                                    <v-col class="d-flex justify-end">
                                                       <ArchivosSeleccionados/>
                                                    </v-col>
                                                    -->
                                                   
                                                  </v-row>
                                                </v-form>
                                            </v-card-text>
                                          </v-card>
                                      </v-window-item>
                                  </v-window>
                                  <v-row v-else>
                                     <v-col>
                                        <v-row>
                                          <v-col>
                                            <div
                                              v-text="'No hay formularios subidos'"
                                              class="header font-weight-light text-center"
                                            ></div>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-img
                                              contain
                                              class="mx-auto"
                                              width="700"
                                              :src="require('../assets/notFound.png')"
                                            ></v-img>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                  </v-row>
                              </v-tab-item>
                              <!-- formulario de empleado enviado por supervisor-->
                              <v-tab-item v-else>
                                <v-sheet cols="12" sm="6" v-if="formulario_empleado.parametros!=null ">
                                  <h3 style="text-align:center;margin-top:15px"> Formulario a llenar:  {{formulario_empleado.nombre}} </h3>
                                  <v-row>
                                    <v-col></v-col>
                                    <v-spacer></v-spacer>
                                    <v-col> 
                                      <v-btn small @click="seleccionar_formulario_empleado(formulario_empleado)" dark color="green">Seleccionar <v-icon>mdi-check</v-icon> </v-btn>
                                    </v-col>

                                  </v-row>
                                  <v-row  class="mt-5 mx-5">
                                    <v-col class="mx-auto" cols="12" sm="4" md="3" v-for="(input,o) in formulario_empleado.parametros" :key="o" >
                                       <v-text-field :label="input.name" v-model="input.value" rounded filled dense></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-btn @click="guardar_formulario(formulario_empleado)" block color="indigo" dark >Guardar formulario </v-btn>
                                </v-sheet>
                                 <v-row v-else>
                                     <v-col>
                                        <v-row>
                                          <v-col>
                                            <div
                                              v-text="'No hay archivos subidos'"
                                              class="header font-weight-light text-center"
                                            ></div>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-img
                                              contain
                                              class="mx-auto"
                                              width="700"
                                              :src="require('../assets/notFound.png')"
                                            ></v-img>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                  </v-row>
                              </v-tab-item>
                            </v-tabs>
                        </v-window>
                      </v-col>
                    </v-row>
                    </v-window-item>
                    <v-window-item>
                      <Digitalizar @regresar="window_acciones_disponibles=1;
                                   $store.state.formularios.inputs_tipo_documental = {campos: [],}" 
                      />
                    </v-window-item>
                  
                    
                    <!---Siguiente ventana -->
                  </v-window>  
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
   
    <v-dialog v-model="dialogo_ver_archivos_seleccionados" max-width=500 >
      <v-toolbar color="blue" dark >
        <v-toolbar-title> Archivos seleccionados </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogo_ver_archivos_seleccionados=false" > <v-icon>mdi-close</v-icon> </v-btn>
      </v-toolbar>
      <ArchivosSeleccionados/>
    </v-dialog>
    <v-dialog v-model="dialogo_delagar">
      <v-card>
        <v-container>
          <v-toolbar dark color="indigo darken-4" rounded="">
            <v-toolbar-title>Delegar caso</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialogo_delagar = false" icon>
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
        </v-container>
        <v-card-text>
          <Delegar
            :parametrosRuta="parametrosRuta"
            @cerrar="dialogo_delagar = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogo_responder_sin_achivos" persistent max-width="500" >
     
        <v-card>
           <v-toolbar dark color="orange darken-4">
            <v-toolbar-title> ATENCION </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <h3 style="text-align:center;margin-top:20px">Estas seguro que deseas responder a este ciclo de trabajo sin seleccionar ningun archivo?</h3>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialogo_responder_sin_achivos=false" color="red" text >Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$store.state.dialogo_respuesta_caso_hijo = true;dialogo_responder_sin_achivos=false" dark color="indigo darken-4">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogo_seleccionar_areas" max-width="500" persistent >
      <Card 
          :titulo="'Seleccione a que areas le enviará una respuesta'" 
          @activar="dialogo_seleccionar_areas=false"
        >
          <template v-slot:contenido >
              <WorkFlowDinamico class="mt-5" :workflow="parametrosRuta.data.workflow" />
              <br>
              <v-btn 
                    :disabled="$store.state.workflows.areas_seleccionadas.length==0" 
                    @click="asignacion_variables('Respondido')"
                    dark block color="indigo darken-4"
                > 
                Enviar respuesta 
              </v-btn>
          </template>
      </Card>
    </v-dialog>
  
  </v-container>
</template>
<script>
import Archivos from "./Archivos";
import Drop from "./Drop";
import Delegar from "./supervisor/Delegar";
import ArchivosSeleccionados from "./ArchivosSeleccionados";
import WorkFlowDinamico from "../components/comunes/WorkFlowDinamico.vue"
import Digitalizar from "./Digitalizar";

const axios = require("axios").default;

export default {
  components: {
    Archivos,
    Delegar,
    Drop,
    ArchivosSeleccionados,
    Digitalizar,
    WorkFlowDinamico
  },
  props: [
    "inputs",
    "archivos",
    "formularios_carpeta_publica",

    "parametrosRuta",
    "dialogo_respuesta_caso",
    "datos_adjuntos",
  ],
  data() {
    return {
      selectItem: 0,

      selectItem_asignados: 0,

      mensaje: "",
      comentario: "",
      color_dialogo: "",
      tipo: "",

      window: 0,
      window2: 0,
      window_acciones: 0,
      window_docs: 0,
      window_acciones_disponibles: 0,
      window_formularios_carpeta_publica: 0,
      barra_herramientas: false,
      window_formularios: 0,
      dialogo_delagar: false,

      boton_responder_caso: false,
      boton_digitalizar_archivos: false,
      
      


      dialogo_respuesta_caso_hijo: false,
      dialogo_responder_sin_achivos: false,
      dialogo_ver_archivos_seleccionados: false,
      objectId: "",
      formulario_empleado: {
        parametros: [],
      },
      formularios_supervisor: [],

      dialogo_seleccionar_areas: false
      
    };
  },
  beforeDestroy(){
    this.$store.state.archivos_seleccionados = []
  },
  watch: {
    dialogo_respuesta_caso: function () {
      console.log("Ejecutando");
      this.dialogo_respuesta_caso_hijo = this.dialogo_respuesta_caso;
      this.comentario = "";
    },
  },
  created() {
    console.log('Hola')
    console.log(this.parametrosRuta.data.estado);
    console.log(this.parametrosRuta.data.objectId);
    console.log(this.parametrosRuta)
    if(this.parametrosRuta.data.estado=='Reasignado'){
      this.parametrosRuta.data.estado =  'Aceptado'
    }
    console.log(this.formularios_carpeta_publica)
    console.log(this.$store.state.dataUser.dataUser)
    console.log(this.$store.state.dataUser.permisos_usuario)
    if(this.$store.state.dataUser.dataUser.rango=='Empleado'){
      if(typeof this.parametrosRuta.data.formulario!='undefined' && this.parametrosRuta.data.formulario!=null ){
      
          this.formulario_empleado = {...this.parametrosRuta.data.formulario}
          console.log(this.formulario_empleado)
      }
    }else{
      if(typeof this.parametrosRuta.data.formularios!='undefined'){
        if(this.parametrosRuta.data.formularios.length>0){
          this.formularios_supervisor = [...this.parametrosRuta.data.formularios]
          console.log(this.formularios_supervisor)
        }
      }
      console.log('supervisor')
    }
    
  },
  methods: {
    validar_digitalizar(){
      if(this.$store.state.archivos_seleccionados.length==0){
        this.$store.commit('alerta','No has seleccionado archivos para digitalizar. Al digitalizar archivos debes seleccionar aquellos formularios o archivos que pertenezcan al mismo tipo documental para digitalizarlos en la plataforma')
      }else{
        this.window_acciones_disponibles = 2
      }
    },
    definir_tipo_respuesta(informacion){
      console.log(informacion)
      if(informacion.ciclo_fijo || this.$store.state.dataUser.dataUser.rango=='Empleado'){
         this.asignacion_variables('Respondido')
      }else{
        this.dialogo_seleccionar_areas = true
      }
     
    },
    seleccionar(formulario,token){
      console.log(formulario)
      let obj = new Object ()
      obj.tipo = 'Formulario'
      obj.autor = formulario.nombre_empleado
      obj.token = token
      obj.nombre = 'Tipo de formulario: '+ formulario.formulario.nombre+ ' Autor: '+formulario.nombre_empleado
      obj.sector = this.$store.state.dataUser.dataUser.sector
      obj.nombre_sector = this.$store.state.dataUser.dataUser.nombre_sector,
      obj.fecha_adjuntado = formulario.fecha_adjuntado
      obj.parametros = formulario.formulario.parametros
      console.log(obj)
      this.$store.commit('agregar_archivo', obj)
    },
    seleccionar_formulario_empleado(formulario){
        console.log(formulario)
        console.log(Date.parse(new Date()).toString())
       
        let obj = new Object ()
        obj.tipo = 'Formulario'
        obj.autor = 'Tipo de formulario: '+ formulario.nombre+ ' Autor: '+this.$store.state.dataUser.dataUser.nombre
        obj.nombre = 'Tipo de formulario: '+ formulario.nombre+ ' Autor: '+this.$store.state.dataUser.dataUser.nombre
        obj.token = Date.parse(new Date() ).toString()
        obj.sector = this.$store.state.dataUser.dataUser.sector
        obj.nombre_sector = this.$store.state.dataUser.dataUser.nombre_sector
        obj.fecha_adjuntado = Date.parse(new Date())
        obj.parametros = formulario.parametros
        console.log(obj)
        this.$store.commit('agregar_archivo', obj)
    },
    objeto_respuesta(){
        let objeto = {
          estado: "",
          comentario: "",
          formulario: [],
        }
        console.log(this.tipo)
        if(this.$store.state.dataUser.dataUser.rango=='Empleado'){
          objeto = {
            estado: this.tipo,
            comentario: this.comentario,
            formulario: this.formulario_empleado,
          }
        }else{
          if(this.$store.state.dataUser.dataUser.rango=='Supervisor'){
            objeto = {
              estado: this.tipo,
              comentario: this.comentario,
              formulario: this.formularios_supervisor,
            }
          } 
        }

        return objeto
    },
    async guardar_formulario(formulario){
      try {
        console.log(formulario)
        let response = await this.$store.dispatch('llamado_put',
        {url: 'https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados/'+this.parametrosRuta.data.objectId,
            body: {
              "formulario": formulario
            },
            tipo_header: 'parse'
        })
       console.log(response)
        
        this.$store.commit('exitoso','Las respuestas del formulario han sido almacenadas de manera exitosa')
      } catch (error) {
        this.$store.commit('error','Algo ha salido mal almacenando las respuestas del formulario '+error.message)
        console.log(error)
      }
    },
    async archivos_seleccionados(archivos) {
      console.log(archivos);
      this.$store.commit("crearToken", 25);
      let url;
      //---Dado que necesitamos anexar los archivos al array del caso que fue aceptado por el supervisor
      //En los casos de cuando el usuario que los esta subiendo es un empleado y un supervisor
      //El objetct Id que se necesita para hacer el put esta en variables distintas por tanto hacemos esta validacion

      if (this.$store.state.dataUser.dataUser.rango == "Empleado") {
        this.objectId = this.parametrosRuta.data.id_caso_endpoint;
      } else {
        this.objectId = this.parametrosRuta.data.objectId;
      }
      try {
        for (let index = 0; index < archivos.obj.length; index++) {
          let file = new FormData();
          file.append("file", archivos.archivo[index]);
          file.append("texto", this.$store.state.token);
          file.append("nombre", archivos.obj[index].nombre);
          console.log('xdsss')

          let obj = {
            nombre: archivos.obj[index].nombre,
            token: this.$store.state.token,
            tipo: archivos.obj[index].tipo,
            autor: this.$store.state.dataUser.dataUser.nombre,
            sector: this.$store.state.dataUser.dataUser.sector,
            nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
            fecha_adjuntado: Date.parse( new Date() )
            
          };

          obj = JSON.stringify(obj);
          this.$store.commit("auth");

          if (index + 1 == 1) {
            //Upload docu crea la carpeta y anexa la primera imagen
            let responseUploadImg = await axios.post(
              "https://drive.educandote.co/audidoc/uploadDocu.php",
              file,
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log("Upload Imagen 1", responseUploadImg);
          } else {
            //upgradeFolder.php anexa, datos al folder asociado al token

            let response = await axios.post(
              "https://drive.educandote.co/audidoc/upgradeFolder.php",
              file,
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log("Upload Imagen " + index);
            console.log(response);
          }

          this.$store.commit("auth", "parse");
          //Se agregan los archivos al endpoint que contiene el caso del empleado
          if (this.$store.state.dataUser.dataUser.rango == "Empleado") {
            let response = await axios.put(
              "https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados/" +
                this.parametrosRuta.data.objectId,
              {
                datos_adjuntos: {
                  __op: "AddUnique",
                  objects: [obj],
                },
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                  "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log(response);
          }

          console.log(this.parametrosRuta.data);
          // 01-03 Motivo de reasignacion
          // la url debe ir en put
          // la url debe ir en put
          /*
          if (this.$store.state.dataUser.dataUser.rango == "Empleado") {
            if (this.parametrosRuta.data.tipo == "Reasignado") {
              url =
                "https://audidoc.educandote.co/parse/classes/prueba01RespuestasSupervisores/";
            } else {
              url = "https://audidoc.educandote.co/parse/classes/prueba01casos/";
            }
          } else {
            if (this.parametrosRuta.data.estado == "Rechazado") {
              url =
                "https://audidoc.educandote.co/parse/classes/prueba01RespuestasSupervisores/";
            } else {
              url = "https://audidoc.educandote.co/parse/classes/prueba01casos/";
            }
          }
          */
        
          url = "https://audidoc.educandote.co/parse/classes/prueba01casos/";
          //Se agregan los archivos a la informacion la cual podra enviar el supervisor
          //Hay qye dejar una opcion que sea un select en la cual indique cuales datos van a ser elegidos
          //Para ser enviados como respuesta

          let response2 = await axios.put(
            url + this.objectId,
            {
              informacion_area: {
                __op: "AddUnique",
                objects: [obj],
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                Authorization: this.$store.state.header,
              },
            }
          );
          console.log(response2);
        }
        this.$store.commit(
          "exitoso",
          "Los archivos han sido subidos de manera exitosa. Ahora puedes ver los archivos subidos en la seccion de Archivos subidos "
        );

        if (this.$store.state.dataUser.dataUser.rango == "Empleado") {
          this.$emit("buscar", this.parametrosRuta.data.objectId);
        } else {
          let obj = {
            url: url,
            objectId: this.parametrosRuta.data.objectId,
          };
          this.$emit("buscar", obj);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error subiendo tus archivos de respuesta, por favor intenta nuevamente "+error.message
        );
      }
    },
    delegar_caso() {
      console.log("delegando");
    },
    asignacion_variables(tipo) {
      let no_abrir_bottom_sheet = false
      let formulario_lleno = false
        switch (this.$store.state.dataUser.dataUser.rango) {
          case "Empleado":
            if (tipo == "Aceptado") {
              this.mensaje = `
                            Estas seguro que deseas aceptar el caso? Al aceptar el caso accede a dar una respuesta oportuna al mismo , ademas
                            se le notificara a su supervisor que usted ha aceptado el caso`;
              this.color_dialogo = "green";
            } else {
              if (tipo == "Rechazado") {
                this.mensaje = `
                                Estas seguro que deseas rechazar el caso? Una vez rechazado no podras volver a verlo en tu bandeja de entrada de casos
                                quedara almacenado en la ventana de casos rechazados y se le debera enviar una notificacion al supervisor con el motivo de su rechazo
                                        `;
                this.color_dialogo = "red";
              } else {
                if (tipo == "Respondido") {
                  console.log(this.formulario_empleado)
                   for (let index = 0; index < this.formulario_empleado.parametros.length; index++) {
                     if(this.formulario_empleado.parametros[index].value!=""){
                       formulario_lleno = true
                     }
                   }
                   if (this.$store.state.archivos_seleccionados.length == 0 && formulario_lleno==false ) {
                     no_abrir_bottom_sheet = true
                     this.$store.commit('error' ,'No hay ningun archivo seleccionado, y ademas no has llenado el formulario por favor seleccione un archivo de respuesta o complete el formulario para poder responder este caso')
                   }else{
                     this.mensaje = `
                                        Estas seguro que deseas responder este caso? Se enviaran los archivos
                                        que estan en la seccion de archivos subidos, ademas se le notificara
                                        al supervisor que le asigno el caso con su respuesta. Ademas que quedara consignada
                                        en la carpeta de respuestas del caso (La unica persona que podra eliminar dicha informacion sera su supervisor)`;
                      this.color_dialogo = "indigo";
                   } 
                  
                }
              }
            }
            break;
          case "Supervisor":
            if (tipo == "Aceptado") {
              this.mensaje = `Estas seguro que deseas aceptar el caso? Al hacerlo de usted depende la entrega oportuna 
                            a las siguientes areas del flujo de trabajo para responder el caso
                             `;
              this.color_dialogo = "green";
            } else {
              if (tipo == "Rechazado") {
                this.mensaje = `Estas seguro que deseas rechazar el caso? Al hacerlo debera informar la razon por la cual
                                 va a ser rechazado`;
                this.color_dialogo = "red";
               
              } else {
                if (tipo == "Respondido") {
                   if (this.$store.state.archivos_seleccionados.length == 0) {
                     no_abrir_bottom_sheet = true
                     this.dialogo_responder_sin_achivos = true
                   }
                    this.mensaje = `Estas seguro que deseas responder este caso? 
                                    Al responderlo se enviaran todos los archivos que selecciones al siguiente paso del flujo
                                    del tipo documental`;
                    this.color_dialogo = "indigo";
                 
                }else{
                  if(tipo=='Cancelado'){
                      this.mensaje = `Estas seguro que deseas cancelar este caso? 
                                    Al cancelar el caso se le notificara al correo electronico de la persona las razones de la cancelacion del mismo`;
                    this.color_dialogo = "red";
                  }
                }
              }
            }
            break;

          default:
            break;
        }
        if(no_abrir_bottom_sheet==false){ //Condicional que se aplica solo si no hay archivos subidos
          this.$store.state.dialogo_respuesta_caso_hijo = true;
        }
        
        this.tipo = tipo;
    },
  },
};
</script>