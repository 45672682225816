<template>
  <v-form>
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        sm="6"
        xl="4"
        v-for="(input, index) in $store.state.formularios.inputs_tipo_documental
          .campos"
        :key="index"
      >
        <v-text-field
          prepend-icon="mdi-book"
          dense
          filled
          v-show="!text(input.name)"
          rounded
          v-model="input.value"
          :label="input.name"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    text(name) {
      return name == "autor";
    },
  },
};
</script>
