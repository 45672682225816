<template>
  <v-container>
    <div v-text="`Al asignar el caso a empleados ellos podran colaborar con la respuesta del
      mismo, para que posteiormente puedas enviar una respuesta al siguiente
      tramo del flujo de trabajo`" class="header font-weight-light text-center">
      
    </div>
    <br />
    <v-select
      v-model="empleados_seleccionados"
      multiple
      prepend-icon="mdi-account-multiple"
      rounded
      dense
      filled
      label="Selecciona empleados"
      :items="empleados"
    ></v-select>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          rounded
          dense
          filled
          v-model="date"
          label="Fecha limite para los empleados"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn  color="primary" @click="menu = false"> Cancelar </v-btn>
        <v-btn  color="primary" @click="$refs.menu.save(date)">
          Aceptar
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-switch
        v-model="enviar_formulario"
        label="Enviara un formulario que deberan llenar los empleados?"
        color="indigo darken-3"
        hide-details
        class="mb-3"
      ></v-switch>
      <v-select label="Selecciona el formulario a llenar" v-if="enviar_formulario" v-model="tipo_documental_seleccionado" filled dense rounded :items=tipos_documentales return-object item-text="nombre"></v-select>
      <!--
      <v-expansion-panels v-if="enviar_formulario" accordion>
     
    
      <v-expansion-panel>
        <v-expansion-panel-header>Desplegar creacion del formulario</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mx-auto" >
            <v-toolbar dark color="indigo darken-4">
              <v-toolbar-title>Crear el formulario</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="formulario1" v-model="formulario1">    
                  <v-btn block color="indigo" dark @click="campos.push({nombre: ''})" >Agregar campo</v-btn>
                  <v-row v-for="(campo,index) in campos" :key="index" >
                      <v-col cols="11">
                          <v-text-field 
                              :rules="[(v) => !!v || 'Es requerido asignar un nombre al campo']"
                              required v-model="campo.nombre" label="Nombre del campo" rounded filled dense >
                          </v-text-field>
                      </v-col>
                      <v-col cols="1">
                          <v-btn @click="campos.length>1 ?  campos.splice(index, 1) : 'No puedes'" icon color="red" > <v-icon>mdi-delete</v-icon></v-btn>
                      </v-col>
                  </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    -->
    <v-btn
      class="my-5"
      :disabled="(empleados_seleccionados.length == 0 || date == null) || (tipo_documental_seleccionado==null && enviar_formulario==true)"
      dark
      color="indigo"
      block
      @click="delegar_caso"
      >Delegar caso</v-btn
    >
  </v-container>
</template>
<script>
const axios = require("axios").default;
export default {
  props: ["parametrosRuta"],
  data() {
    return {
      empleados: [],
      empleados_seleccionados: [],
      empleados_completo: [],
      date: null,
      menu: false,
      enviar_formulario: false,
      tipo_documental_seleccionado: null,
      tipos_documentales: [],
      //formulario1: true,
      /*
      campos: [
        {
          nombre: '',
        }
      ]
      */
    };
  },
  created() {
    this.consultar_emplados();
    this.consultar_tipos_documentales()
  },
  methods: {
    async consultar_tipos_documentales(){
          let response = await this.$store.dispatch('llamado_get',
          {url: 'https://audidoc.educandote.co/parse/classes/V1_tiposDocumentales',tipo_header:'parse'})

          console.log(response)
          this.tipos_documentales = [...response.data.results]

    },
    async delegar_caso_empleado(empleado,formulario) {
     
      /**
       * Envio al endpoint de los empleados que recibiran los casos
       * Envio correo electronico al correo del usuario
       */
      //01-03 Motivo de reasignacion
      console.log(formulario)
      console.log(this.$route.params.action)
      try {
        let tipo;
        if (this.$route.params.action == "reasignar") {
          tipo = "Reasignado";
        } else {
          tipo = "Pendiente";
        }
        console.log(tipo)
        var f = new Date();
        var fecha =
          f.getFullYear() +
          "-" +
          ("0" + (f.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + f.getDate()).slice(-2);

        let response = await axios.post(
          "https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados/",
          {
            formulario: formulario,
            nombre_empleado: empleado.nombre,
            identificacion: empleado.identificacion,
            correo: empleado.identificacion,
            tipo: tipo,
            nombre_supervisor: this.$store.state.dataUser.dataUser.nombre,
            identificacion_supervisor: this.$store.state.dataUser.dataUser.identificacion,
            correo_supervisor: this.$store.state.dataUser.dataUser.correo,

            nombre_documento: this.parametrosRuta.data.nombre_documento,

            tipo_documental: this.parametrosRuta.data.tipo_documental,
            token: this.parametrosRuta.data.token,
            id_caso_endpoint: this.parametrosRuta.data.objectId, //Con esto edito el valor del array de responsabels si decido aceptarlo
            meta_data: this.parametrosRuta.data.meta_data,
            carpeta_publica: this.parametrosRuta.data.carpeta_publica,
            fecha_asignacion_supervisor: fecha,
            fecha_limite_supervisor: this.date,
            sector: this.$store.state.dataUser.dataUser.sector,
            estado: "Pendiente", //Puede cambiar a rechazado o aceptado y si se acepta se agrega
            //Los datos del empleado al endpoint de prueba01Casos al array de responsables
            //Se envia un correo al supervisor que han aceptado el caso y se envia una confirmacion
            //A un endpoint extra para avisarle al supervisor que si lo haran o no lo haran
            archivos_subidos: [],
            datos_adjuntos: [],
            
            datos_personales_remitente: this.parametrosRuta.data.datos_personales_remitente, //datos del usuario que pide el inicio de una actividad
            datos_del_radicado: {
                tipo_documental: this.parametrosRuta.data.datos_del_radicado.tipo_documental,
                informacion: {
                    asunto: this.parametrosRuta.data.datos_del_radicado.informacion.asunto,
                    descripcion: this.parametrosRuta.data.datos_del_radicado.informacion.descripcion,
                    folios: this.parametrosRuta.data.datos_del_radicado.informacion.folios,
                    anexos: this.parametrosRuta.data.datos_del_radicado.informacion.anexos,
                }
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        this.$store.commit(
          "exitoso",
          "Caso delegado con exito al usuario " +
            empleado.nombre
        );
        console.log(response);
        return true;
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error delegando el caso al empleado: " +
            empleado.nombre+ " "+error.message
        );
        return false;
      }
    },
    async delegar_caso() {
      this.$emit('cerrar')
      let ward = true;
      for (
        let index = 0;
        index < this.empleados_seleccionados.length;
        index++
      ) {
        for (
          let index2 = 0;
          index2 < this.empleados_completo.length;
          index2++
        ) {
          if (this.empleados_seleccionados[index] ==this.empleados_completo[index2].nombre) {
            ward = ward *(await this.delegar_caso_empleado(this.empleados_completo[index2],this.tipo_documental_seleccionado));
          }
        }
      }
      if (ward == 1) {
        this.date = null;
        this.empleados_seleccionados = [];
        this.$store.commit(
          "exitoso",
          "El caso ha sido delegado de manera exitosa a todos los empleados"
        );
      }
      this.campos = [
        {
          nombre: '',
        }
      ]
    },
    async consultar_emplados() {
      //Poner una validacion extra de que es un supervisor el que esta ejecutando esta funcion
      try {
        this.$store.commit("auth", "parse");
        console.log(this.$store.state.dataUser.dataUser.sector);

        let buscar = JSON.stringify({
          rango: "Empleado",
          sector: this.$store.state.dataUser.dataUser.sector,
        });

        buscar = encodeURIComponent(buscar);

        let response = await axios.get(
          "https://audidoc.educandote.co/parse/classes/prueba03Usuarios?where=" +
            buscar,

          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );

        console.log(response.data);
        for (let index = 0; index < response.data.results.length; index++) {
          this.empleados.push(response.data.results[index].nombre);
        }

        this.empleados_completo = response.data.results;
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error consultando los empleados del sector. por favor intentalo mas tarde"
        );
      }
    },
  },
};
</script>