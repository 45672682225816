<template>
  <div>
    <h4 style="text-align:center"> Sube un archivo desde el equipo </h4>
    <div
      class="div pa-2 text-center"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <v-col cols="12" class="mx-auto">
        <v-file-input
          outlined
          chips
          v-model="filelist"
          placeholder="Añadir archivos"
          multiple
          counter
          prepend-icon="mdi-paperclip"
          @change="onChange"
          name="fields[assetsFieldHandle][]"
          id="assetsFieldHandle"
          ref="file"
        ></v-file-input>
      </v-col>
      <p>Tambien puedes subir los archivos arrastrandolos hasta esta zona</p>
    </div>

    <v-btn
      :disabled="filelist.length == 0"
      @click="emitConst"
      block
      color="black white--text"
    >
      Guardar
    </v-btn>
    <v-dialog v-model="$store.state.load" fullscreen open-delay="0" transition="fade-transition">
      <v-sheet color="rgba(0,0,0,.5)" height="100%">
        <v-container fluid class="fill-height">
          <v-row align="center" justify="center">
            <v-progress-linear
              indeterminate
              class="mx-auto text-center"
              color="blue darken-4"
            />
            <v-col cols="12">
              <div
                v-text="'Cargado datos...'"
                class="header font-weight-ligth text-center white--text"
              ></div>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["permitir"],
  data() {
    return {
      filelist: [],
      archivo: [],
      objUpload: [],
      dialogo: true,
    };
  },
  methods: {
    emitConst() {
      let aux = {
        archivo: this.archivo,
        obj: this.objUpload,
      };
      this.$store.state.load = true;
      try {
        console.log("FunciÃ³n");

        this.$emit("info", aux);

        this.filelist = [];
        this.objUpload = [];
      } catch (error) {
        console.log(error);
      }
    },
    onChange(event) {
      // this.filelist = [...this.$refs.file.files];
      //   event.preventDefault();
      console.log(event);

      //    for (let index = 0; index < event.dataTransfer.files.length; index++) {
      //     this.filelist.push(event.dataTransfer.files[index]);
      //   }
      //this.filelist.push(this.$refs.file.files);

      this.archivo = event;
      let aux;
      for (let index = 0; index < event.length; index++) {
        console.log(this.archivo[index].name);
        aux = this.archivo[index].name.split(".");
        console.log(aux)
        console.log(aux.length)
        this.objUpload.push({
          tipo: aux[aux.length-1], // Se cambio puesto que si el archivo . en el nombre el tipo debe ser el ultimo del split
          nombre: aux[0].replace(/ /g, ""),
        });
      }
    },
    drop(event) {
      event.preventDefault();
      console.log("hola");
      console.log(event.dataTransfer.files[0]);
      let aux;
      for (let index = 0; index < event.dataTransfer.files.length; index++) {
        this.filelist.push(event.dataTransfer.files[index]);
        this.archivo.push(event.dataTransfer.files[index]);
        aux = event.dataTransfer.files[index].name.split(".");
        console.log(aux)
        console.log(aux.length)
        this.objUpload.push({
          tipo: aux[aux.length-1], // Se cambio puesto que si el archivo . en el nombre el tipo debe ser el ultimo del split
          nombre: aux[0].replace(/ /g, ""),
        });
      }

      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>

<style lang="scss" scoped>
.div {
  border: rgb(255, 255, 255) solid;
  background: rgb(235, 233, 233);
}
</style>