<template>
  <v-app>
    <v-main>
      <Caso
        v-on:responder="responder"
        v-on:volver="volver"
        v-on:buscar="buscar_archivos_subidos_supervisor"
        :inputs="inputs"
        :parametrosRuta="parametrosRuta"
        :archivos="archivos"
        :dialogo_respuesta_caso="dialogo_respuesta_caso"
        :datos_adjuntos="datos_adjuntos"
        :formularios_carpeta_publica="formularios_carpeta_publica"
      />
    </v-main>
  </v-app>
</template>
<script>
const axios = require("axios").default;
import Caso from "../../components/Caso";
import acciones_casos_supervisor from "../../mixins/acciones_casos_supervisor";
import { EventBus } from '../../event/event-bus';
export default {
  mixins: [acciones_casos_supervisor], //Funciones del mixin aceptar_caso / rechazar_caso
  components: {
    Caso,
  },
  data() {
    return {
      parametrosRuta: {},
      inputs: [],
      archivos: [],
      formularios_carpeta_publica: [],

      window: 0,
      datos_adjuntos: [], //Array que contendra un objeto {nombre, token, tipo, autor, sector}

      dialogo_respuesta_caso: false,
      comentario: "",
    };
  },
  mounted(){
    console.log('das')
    EventBus.$on('recargarSupervisor', (payload) => {
      console.log(payload)
      this.buscar_archivos_subidos_supervisor(payload)
    })
    
  },
  created() {
    console.log(this.$route.params.id)
    let decode = atob(this.$route.params.id)
    console.log(decode);
    decode = JSON.parse(decode);
    console.log('decode')
    console.log(decode);
    this.parametrosRuta = decode.data;

    let _this = this;

    console.log(this.parametrosRuta.data);

    for (
      let index = 0;
      index < this.parametrosRuta.data.informacion_area.length;
      index++
    ) {
      try {
        this.datos_adjuntos.push(JSON.parse(this.parametrosRuta.data.informacion_area[index]));
      } catch (error) {
        console.log(error)
        this.datos_adjuntos.push(this.parametrosRuta.data.informacion_area[index])
      }
      
    }
    let info 
    for (
      let index = 0;
      index < this.parametrosRuta.data.carpeta_publica.length;
      index++
    ) {
      
      try {
        info = JSON.parse(this.parametrosRuta.data.carpeta_publica[index])
        
      } catch (error) {
        info = this.parametrosRuta.data.carpeta_publica[index]
      
      }
      console.log(info)
      if(info.tipo=='Formulario'){
        this.formularios_carpeta_publica.push(info)
      }else{
        this.archivos.push(info)
      }
      
    }
    Object.keys(this.parametrosRuta.data.meta_data).forEach(function (key) {
      //Quitar los _ y colocar espacios ademas de a la key de fecha limite colocar: FECHA LIMITE DEL CASO EN DIAS HABILES
      _this.inputs.push({
        input: key,
        value: _this.parametrosRuta.data.meta_data[key],
      });
    });
  },
  methods: {
    async buscar_archivos_subidos_supervisor(obj) {
      console.log("supervisor");
      console.log(obj);
      try {
        this.datos_adjuntos = [];
        this.$store.commit("auth", "parse");
        let response = await axios.get(obj.url + obj.objectId, {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
            "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
            Authorization: this.$store.state.header,
          },
        });
        console.log(response.data);
        this.parametrosRuta.data.informacion_area =response.data.informacion_area;
        console.log(this.parametrosRuta.data);
        for (
          let index = 0;
          index < response.data.informacion_area.length;
          index++
        ) {
          this.datos_adjuntos.push(
            JSON.parse(response.data.informacion_area[index])
          );
        }
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error consultando los archivos subidos intenta mas tarde"
        );
      }
    },
    volver() {
      console.log("Volviendo");
    },
   async responder(tipo) {
      //tipo.estado puede tener 3 tipos de estados Aceptado Rechazado y Respondido
      
      this.comentario = tipo.comentario;
      /*
      var f = new Date();
      var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)
      */
      try {
        switch (tipo.estado) {
          case "Aceptado":
            {
              console.log(this.$store.state.dataUser.dataUser.nombre, this.$store.state.dataUser.dataUser.identificacion)

                //Segun sea el flujo se le debe notificar al sector anteior que el caso fue aceptado
                //Si esta area es la primera despues del recepcionista y es aceptado se le notificara
                //el caso al correo electronico de la persona, pero si es un sector que este mas abajo
                //se le notificara a la bandeja de entrada del supervisor que hizo que el caso siguiera el flujo
               
                let body = {
                    "nombre_supervisor": this.$store.state.dataUser.dataUser.nombre,
                    "identificacion_supervisor": this.$store.state.dataUser.dataUser.identificacion,
                    "correo_supervisor": this.$store.state.dataUser.dataUser.correo,
                    "userId_supervisor": this.$store.state.dataUser.dataUser.userId,
                    "estado": 'Aceptado',
                    "fecha_aceptado": Date.parse(new Date()),
                    "responsables": [
                        {
                            nombre: this.$store.state.dataUser.dataUser.nombre,
                            identificacion: this.$store.state.dataUser.dataUser.identificacion,
                            correo: this.$store.state.dataUser.dataUser.correo,
                            cargo: this.$store.state.dataUser.dataUser.rango,
                        }
                    ]

                }
              await this.enviar_estado_respuesta(body)
            }
            break;
          case "Rechazado":
            {
              let body = {
                      "nombre_supervisor": this.$store.state.dataUser.dataUser.nombre,
                      "identificacion_supervisor": this.$store.state.dataUser.dataUser.identificacion,
                      "correo_supervisor": this.$store.state.dataUser.dataUser.correo,
                       "userId_supervisor": this.$store.state.dataUser.dataUser.userId,
                      "estado": 'Rechazado',
                      "fecha_rechazado": Date.parse(new Date()),
                      "responsables": [],
                      "comentario": this.comentario, //Esta seria la razon del rechazo


                  }
                await this.enviar_estado_respuesta(body)
                console.log('Rechazar caso')
            
            }
           

            break;
          case "Respondido":
            {
              console.log(this.parametrosRuta.data)
              console.log(this.$route.params.action)
              
              if (this.parametrosRuta.data.estado == 'Respondido') { //En caso de la reasignacion
                 await this.enviar_informacion_al_siguiente_sector('Respondido', this.comentario)
              } else { //En caso de una revision normal 
                

                  //En esta parte del rechazar caso 

                  let body = {
                      "nombre_supervisor": this.$store.state.dataUser.dataUser.nombre,
                      "identificacion_supervisor": this.$store.state.dataUser.dataUser.identificacion,
                      "correo_supervisor": this.$store.state.dataUser.dataUser.correo,
                      "userId_supervisor": this.$store.state.dataUser.dataUser.userId,
                      "estado": 'Respondido',
                      "fecha_respondido": Date.parse(new Date()),
                  }
                  await  this.enviar_estado_respuesta(body)
              }
            }
            break;
          case "Cancelado":
            {
            
              //en el body falta
              //Razon del rechazo 

              let body = {
                  "nombre_supervisor": this.$store.state.dataUser.dataUser.nombre,
                  "identificacion_supervisor": this.$store.state.dataUser.dataUser.identificacion,
                  "correo_supervisor": this.$store.state.dataUser.dataUser.correo,
                  "estado": 'Cancelado',
                  "fecha_rechazado": Date.parse(new Date()),
                  "responsables": [],
                  "comentario": this.comentario, //Esta seria la razon del rechazo


              }
                await this.enviar_estado_respuesta(body)
                console.log('Cancelar caso')
            }
            

            break;  

          default:
            break;
        }
        this.parametrosRuta.data.estado = tipo.estado;
        this.$store.state.dialogo_respuesta_caso_hijo = false;
        //this.$store.state.load_linear = true;
        if(tipo.estado!='Aceptado'){
          /*
          this.$router.push(
            "/supervisor/home_supervisor/CasosAsignados/" +
              this.$store.state.tipo_para_arreglar_problas_de_ruta
          );
          */
         this.$router.go(-1)
        }
        
        
       
      } catch (error) {
        console.log(error);
      }

      console.log(tipo);
    },
  },
};
</script>