<template>
    <v-list>
        <v-subheader v-if="$store.state.archivos_seleccionados.length > 0"
          >Archivos Seleccionados</v-subheader
        >
        <v-subheader v-else>
          No hay archivos seleccionados
        </v-subheader>
        <v-list-item
          v-for="item in $store.state.archivos_seleccionados"
          :key="item.token"
        >
       
          <v-list-item-avatar
            ><v-icon color="indigo"
              >mdi-file-multiple</v-icon
            ></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-title> {{ item.nombre }} </v-list-item-title>
            <v-list-item-subtitle> {{ item.tipo }} </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              x-small
              fab
              dark
              color="red darken-4"
              text
              @click="$store.commit('eliminar_archivo_array', item.token)"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-list-item-action
          >
        </v-list-item>
      </v-list>
</template>
<script>
export default {
    methods: {
      
    }
}
</script>