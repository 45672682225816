

const axios = require('axios').default
var acciones_casos_supervisor = {
    methods: {
        async notificar_respuesta(tipo) {

            console.log(tipo)
            console.log(this.parametrosRuta.data)
            //En ambas funciones a continuacion se le debe pasar un comentario en dado caso el documento haya sido
            //Rechazado por cualquier motivo, es decir que si se rechaza se debe abrir un dialogo que le avise a la
            //Persona PORQUE ESTAN RECHAZANDO EL DOCUMENTO
            let respuesta
            if (this.parametrosRuta.data.inicio == true  && tipo != 'Respondido') { //Esta condicion va a cambiar cuando
                console.log('Entra por aqui?')
                respuesta = await this.notificar_al_correo(tipo, this.comentario) //Cuando es la primera instancia del flujo se debe notificar
                //Al correo de la persona que pidio la respuesta a su documento que su caso fue aceptado o rechazado 
            } else {
                if (tipo == 'Respondido') {
                    respuesta = await this.enviar_informacion_al_siguiente_sector(tipo, this.comentario)
                } else {

                    respuesta = await this.notificar_al_sector(tipo, this.comentario)
                }
                //Sino es la primera instancia se le notifica al supervisor que remitio El caso a la siguiente area del flujo que el caso fue aceptado o rechazado
            }

            return respuesta
        },
        async notificar_al_sector(tipo, comentario) {

            //Seria bueno notificar al correo electronico del remitente que estamos enviando una respuesta
            //De aceptado o rechazado
            console.log(tipo)
            console.log(this.parametrosRuta.data)
            var f = new Date();
            var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)
            if(this.parametrosRuta.data.area_remitente!='Notificar al usuario'){
                try {
                    let response = await axios.post('https://audidoc.educandote.co/parse/classes/prueba01RespuestasSupervisores/',
                        {
                            //---Datos del sector quien envio el caso al cual debemos notificar si aceptamos o rechazamos
                            "sector_supervisor": this.parametrosRuta.data.area_remitente,
                            "nombre_supervisor": this.parametrosRuta.data.remitente,
                            "correo_supervisor": this.parametrosRuta.data.correo_remitente,
                            "identificacion_supervisor": this.parametrosRuta.data.identificacion_remitente,
                            "userId_supervisor": this.parametrosRuta.data.userId,
                            
                            "objectId_remitente": this.parametrosRuta.data.objectId,//Valor util para las reasignaciones
    
                
                            "sector_remitente": this.parametrosRuta.data.sector,
                            "nombre_remitente": this.$store.state.dataUser.dataUser.nombre,
                            "identificacion_remitente": this.$store.state.dataUser.dataUser.identificacion,
                            "userId_remitentente": this.$store.state.dataUser.dataUser.userId,
                            "correo_remitente": this.$store.state.dataUser.dataUser.correo,
    
                            "fecha": fecha,
                            "nombre_documento": this.parametrosRuta.data.nombre_documento,
                            "token": this.parametrosRuta.data.token,
                            "tipo_documental": this.parametrosRuta.data.tipo_documental,
    
                            //Datos del caso al cual estamos respondiendo
                            
                            "estado": tipo, //Tipo puede ser eaceptado o rechazado
                            "comentario": comentario,
                            "reasignado": false, //Campo que indica si ya me lo reasigne
                           
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                                'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                                'Authorization': this.$store.state.header
                            }
                        })
                    
                    if(!this.parametrosRuta.data.ciclo_fijo && this.parametrosRuta.data.estado=='Reasignado'){
                        console.log(this.parametrosRuta.data)

                        for (let index = 0; index < this.parametrosRuta.data.workflow.length; index++) {
                            
                            if(this.parametrosRuta.data.workflow[index].id==this.parametrosRuta.data.id_nodo_sector){
                                this.parametrosRuta.data.workflow[index].data.reasignado = true
                            }
                        }

                        console.log(this.parametrosRuta.data.workflow)
                        
                        try {
                            console.log(this.parametrosRuta)
                            let actualizar = await this.$store.dispatch('llamado_put',{
                                url: "https://audidoc.educandote.co/parse/classes/prueba01casos/"+this.parametrosRuta.data.objectId,
                                body: {
                                    workflow: this.parametrosRuta.data.workflow
                                },
                                tipo_header: "parse"
                            }) 
                            console.log(actualizar)
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    console.log(response)
                    return {res: true, mensaje: 'Se ha notificado la respuesta al area que remitio este ciclo de trabajo' }
                } catch (error) {
                    console.log(error)
                    console.log(error.message)
                    return {res: false, mensaje: 'Algo ha salido mal notificando la respuesta al area que remitio el ciclo de trabajo' }
                }
            }else{ //Funcione que notifica al usuario si se cancela, rechaza, o acepta la respuesta que el dio 
                   //Usando la vista de info_usuario.vue
                   let res
                   if(tipo=='Rechazado'){
                        this.$route.params.action = 'reasignar'
                    
                     res = await this.enviar_informacion_al_siguiente_sector(tipo,this.comentario)
                   }else{
                     res = await this.notificar_al_correo(tipo,this.comentario)
                   }
                   
                return res
            }
            

        },
        async notificar_al_correo(tipo, comentario,info) {
            console.log(tipo) //Aqui
            /*
            var username = "7c234d3ef69483eda10d1cd5718e69a1";
            var password = "a28863fbbfb59889700ef0039abbbc14";
            var credentials = btoa(username + ":" + password);
            var basicAuth = "Basic " + credentials;
            */
            let correo
            switch (tipo) {
                /*
                case 'Rechazado':
                    correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    "</h3><br/>Ha sido " + tipo + " Con motivo de: " + comentario
                    break;
                case 'Cancelado':
                    correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    "</h3><br/>Ha sido" + tipo + "Con motivo de: " + comentario

                    break;
                 */   
                case 'Notificar':
                    correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    `</h3><br/> Le informa que debe Realizar una "Accion" e informar a la empresa cuando lo haya realizado. 
                        Puede comunicarse llamando a nuestra empresa, enviando un correo
                        ingresando al siguiente enlace: http://localhost:8080/info_usuarios/`+info+
                    `<br/> Informacion adicional: `+comentario
                    break;
                case 'Notificar2':
                    correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    `</h3>
                    <br/> 
                    <h2> Bienvenidos al servicio de internet de aulas digitales de Colombia </h2>
                    <br/>
                        Para terminar este proceso de instalación del servicio, es necesario que se dirija a nuestras oficinas ubicadas en la avenida del ferrocarril # 29E-46 oficina 412, con los siguientes documentos:
                    <br/>
                     
                    
                    1.       Recibo de pago con el costo de la instalación y con el monto del primer mes de su paquete de internet. Puede pagar en la oficina.
                    <br/>
                    2.       El contrato firmado.
                    <br/>
                     
                    
                    Se instalará el servicio de internet en un plazo no superior a 7 días hábiles.
                    <br/>
                    Si tiene dudas o inquietudes por favor no dude en contactarnos.
                    <br/>
                    
                    
                    Teléfono: <strong>  3206864040 <strong/>
                    <br/>
                    Correo: <strong> asistente@audicolsas.co  <strong/> 
                    <br/> 
                    Informacion adicional:  `+comentario
               
                break;         
            
                default:
                    correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    "</h3><br/>Ha sido " + tipo + " Con motivo de: " + comentario
                    break;
            }
            
            try {
                let body = {"operation":"sendMail","key":"7c234d3ef69483e","token":"ad140c41a20cb6c",
                "para": this.parametrosRuta.data.correo_de_contacto,"asunto": this.parametrosRuta.data.nombre_documento+' '+ this.parametrosRuta.data.tipo_documental +' '+ new Date()  ,"mensaje":correo}
                
                console.log(body)
                var response = await axios.post("https://audidoc.educandote.co/mailjet/enviar_correo.php",
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json ",
                            
                        },
                    })
                console.log(response)
                return {res: true, mensaje: 'La informacion ha sido enviada de manera exitosa al correo de contacto de la persona'}
            } catch (error) {
                console.log(error)
                return {res: false, mensaje: 'Error notificando la informacion al correo de contacto de la persona en esta instancia del flujo '+error.message}
            }
        },
        async enviar_informacion_al_siguiente_sector(tipo, comentario) {
            console.log(tipo)
            console.log(comentario)
            var f = new Date();
            var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)

            //Contendra los archivos de la carpeta publica ademas de los que esten en informacion area 
            //Ya que a la siguiente area los archivos de esta area + los archivos de la carpeta publica (actual)
            //Seran la carpeta publica nueva

            for (let index = 0; index < this.$store.state.archivos_seleccionados.length; index++) {
                this.$store.state.archivos_seleccionados[index] = JSON.stringify(this.$store.state.archivos_seleccionados[index])
                
            }

            let carpeta_publica = [...this.parametrosRuta.data.carpeta_publica, ...this.$store.state.archivos_seleccionados]

            //Si se esta aceptando o rechazando se envia un post al sector que le asigno el caso 
            //Al sector actual, pporque vamos a tener los campos de remitente, identificacion_remitente, correo_remitente

            //Pero si estamos respondiendo un caso debemos enviarlo a todos los sectores que esten justo por debajo en el workflow
            //Con la nueva carpeta_publica que contendra los archivos subidos del supervisor actual y sus empleados delegados

            //Area remitente

            let sectores = []

            //
            console.log(this.$route.params.action)
            console.log(this.parametrosRuta.data)
            if(this.parametrosRuta.data.ciclo_fijo){
                for (let index = 0; index < this.parametrosRuta.data.workflow.length; index++) {

                    //Explicacion: BUSCAR LA HOJA DEL MAPA CONCEPTUAL 01-03 Motivo de reasignacion
    
                    if (this.$route.params.action == 'reasignar') { //En caso de que sea un reasignar
    
                        if (this.parametrosRuta.data.workflow[index].id == this.parametrosRuta.data.id_nodo_sector_anterior_numeric
                        ) {
                            console.log(this.parametrosRuta.data.workflow[index])
                            sectores.push({
                                nombre_sector: this.parametrosRuta.data.workflow[index].data.title,
                                id_nodo_sector: this.parametrosRuta.data.workflow[index].id,
                                area_id: this.parametrosRuta.data.workflow[index].data.area_id,
                            })
                            console.log('ENTRANDO A LA REASIGNACION CON LAS VARIABLES')
                            console.log(sectores)
                        }
                    } else {//En caso de que sea un ingreso de revisar 
                        if (this.parametrosRuta.data.workflow[index].parentId == this.parametrosRuta.data.id_nodo_sector) {
                            sectores.push({
                                area_id: this.parametrosRuta.data.workflow[index].data.area_id,
                                nombre_sector: this.parametrosRuta.data.workflow[index].data.title,
                                id_nodo_sector: this.parametrosRuta.data.workflow[index].id
                            })
                        }
                    }
    
    
                    /* Antigua forma
                   if(this.parametrosRuta.data.workflow[index].parentId==this.parametrosRuta.data.id_nodo_sector){
                        sectores.push({
                            nombre_sector: this.parametrosRuta.data.workflow[index].data.title,
                            id_nodo_sector: this.parametrosRuta.data.workflow[index].id
                        })
                   }
                     */
                }
            }else{


                this.parametrosRuta.data.workflow = await this.$store.dispatch('workflows/agregar_nodos_workflow',
                {areas: this.$store.state.workflows.areas_seleccionadas, parentId: parseInt(this.parametrosRuta.data.id_nodo_sector) })
                
                sectores = await this.$store.dispatch('workflows/buscar_sectores',{workflow: this.parametrosRuta.data.workflow,
                                                                                    id: this.parametrosRuta.data.id_nodo_sector,
                                                                                   });
            }
           

            let obj = {}
            obj.remitente = this.$store.state.dataUser.dataUser.nombre
            obj.identificacion_remitente = this.$store.state.dataUser.dataUser.identificacion //Lo puedo borrar
            obj.userId_remitente = this.$store.state.dataUser.dataUser.userId
            obj.correo_remitente = this.$store.state.dataUser.dataUser.correo
            obj.area_remitente = this.$store.state.dataUser.dataUser.sector
            
            obj.objectId_remitente = this.parametrosRuta.data.objectId
            //-------------------------------------------------------
            //Datos para realizar los informes y reportes por fechas------------------
            obj.fecha_aceptado = null, //Cambiara de null a la fecha si el caso es aceptado por un supervisor
            obj.fecha_rechazado = null, //Cambiara de null a la fecha si el caso es rechazado por un supervisor
            obj.fecha_respondido = null, //Cambiara de null a la fecha cuando el caso es respondido por un supervisor
            //---------------------------------------                
            obj.fecha_asignacion_caso = fecha
            obj.fecha_asignacion_int = Date.parse(new Date())

            obj.tipo_documental = this.parametrosRuta.data.tipo_documental
            obj.nombre_documento = this.parametrosRuta.data.nombre_documento //Nombre del documento con el cual se recibira en la bandeja de entrada
            obj.meta_data = this.parametrosRuta.data.meta_data //Son los inputs del tipo documental seleccionado 
            obj.token = this.parametrosRuta.data.token //Token del caso
            obj.estado = 'Pendiente' // Este estado indicara "si esta en progreso, o ya fue respondido, rechazado, aceptado"

            obj.nombre_supervisor = null //Campo que se llenara una vez algun supervisor haya aceptado el caso
            obj.identificacion_supervisor = null //se llenera cuando algun supervisor acepte
            obj.correo_supervisor = null //Se llenara una vez algun supervisor acepte
            obj.userId_supervisor = null

            obj.responsables = [] // los responsables del area al que ingresa sera un array de objetos de la forma: {nombre: 'fry', identificacion: 1004 } este array se llenara en la vista del supervisor
            obj.workflow = this.parametrosRuta.data.workflow  //workflow asignado por el recepcionista que indica las areas por las que pasa el documento

            obj.fecha_salida = null // fecha en la que se culmina su proceso dentro del area y es enviado a otra area o al usuario final
            //obj.fecha_limite_caso = this.parametrosRuta.data.fecha_limite_caso //Limite legal del documento establecido en el tipo documental
            obj.correo_de_contacto = this.parametrosRuta.data.correo_de_contacto
            obj.informacion_area = []
            obj.carpeta_publica = carpeta_publica
            obj.id_nodo_sector_anterior_numeric = this.parametrosRuta.data.id_nodo_sector  //agregado 01-03
            //obj.formularios = [...this.parametrosRuta.data.formularios] 
            obj.comentario = comentario
            obj.ciclo_fijo = this.parametrosRuta.data.ciclo_fijo

            obj.datos_personales_remitente = this.parametrosRuta.data.datos_personales_remitente, //datos del usuario que pide el inicio de una actividad
            obj.datos_del_radicado= {
                tipo_documental: this.parametrosRuta.data.datos_del_radicado.tipo_documental,
                informacion: {
                    asunto: this.parametrosRuta.data.datos_del_radicado.informacion.asunto,
                    descripcion: this.parametrosRuta.data.datos_del_radicado.informacion.descripcion,
                    folios: this.parametrosRuta.data.datos_del_radicado.informacion.folios,
                    anexos: this.parametrosRuta.data.datos_del_radicado.informacion.anexos,
                }
            }

            console.log(obj)
            let res
            try {
                console.log(sectores)
                
                this.$store.commit('auth', 'parse')
                for (let index = 0; index < sectores.length; index++) {
                    let response
                    switch (sectores[index].nombre_sector) {
                        case 'Fin del proceso':
                            console.log('Enviar datos de la carpeta publica al correo de contacto de la persona')
                            res =  await this.fin_del_proceso(carpeta_publica)
                            
                            break;
                        case 'Notificar al usuario':
                            console.log('Notificar al usuario')
                            obj.sector = sectores[index].nombre_sector
                            obj.id_nodo_sector = sectores[index].id_nodo_sector
                            obj.area_id = sectores[index].area_id.toString()


                            response = await axios.post('https://audidoc.educandote.co/parse/classes/prueba01casos',
                                obj,
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                                        'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                                        'Authorization': this.$store.state.header
                                    }
                                })
                            console.log(response)
                          
                            res = await this.notificar_al_correo('Notificar2',this.comentario)
                            //Si se quiere que el cliente suba un documento e interactue con la plataforma
                            //
                            //Usar la funcion: 
                            // let info
                            //  info = response.data.objectId+'|'+sectores[index].id_nodo_sector
                            //res = await this.notificar_al_correo('Notificar',this.comentario,info)
                            //Esta funcion habilita una vista llamada info_usuarios.vue
                            //Esta funcion deberia figurar como un permiso asociado al flujo de trabajo 
                            //Ejm una opcion en el nodo de Notificar al usuario donde se indique si solo es un correo
                            //Informativo o si es un correo en el que se le dice que requiere interactuar
                            //Con la plataforma 
                           
                           break;    
                    
                        default:
                            
                            obj.sector = sectores[index].nombre_sector
                            obj.id_nodo_sector = sectores[index].id_nodo_sector
                            obj.area_id = sectores[index].area_id.toString()

                            console.log(obj)
                            response = await axios.post('https://audidoc.educandote.co/parse/classes/prueba01casos',
                                obj,
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                                        'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                                        'Authorization': this.$store.state.header
                                    }
                                })
                            console.log(response)
                            res = { res: true, mensaje: 'La informacion ha sido enviada a las siguientes areas en el flujo de manera exitosa' }
                            break;
                    }
                }
                
            } catch (error) {
               
                console.log(error)
                console.log(error.message)
                res = {res: false, mensaje: 'Algo ha salido mal enviando la informacion a las siguientes areas '+error.message }
            }
            this.$store.state.workflows.workflow = []
            this.$store.state.workflows.areas_seleccionadas = []
            return res //Los return true indican que el proceso de la funcion ya acabo
        },
        async enviar_estado_respuesta(body) {
            console.log(body)
            let notificado 
            try {
                
                
                if(body.estado!='Cancelado'){
                     notificado = await this.notificar_respuesta(body.estado)
                }else{
                     notificado =  await this.notificar_al_correo('Cancelado',this.comentario)
                }    
                console.log(notificado)
                if(notificado.res){
                    this.$store.commit('auth', 'parse')
                    let response = await axios.put('https://audidoc.educandote.co/parse/classes/prueba01casos/' + this.parametrosRuta.data.objectId,
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                    })
                    let mensaje = ''
                    if(body.estado=='Aceptado'){
                        mensaje = 'Ahora puedes delegar el caso a algunos de los empleados del sector en la ventana que se habilito debajo'
                    }
                    this.$store.commit('exitoso', 'El caso ha sido ' + body.estado.toLowerCase() + ' de manera exitosa. '+mensaje)
                    console.log(response)
                }else{
                    this.$store.commit('error', notificado.mensaje)
                }
                
                this.dialogo_respuesta_caso = true
                this.dialogo_respuesta_caso = false
            } catch (error) {
                console.log(error)
                console.log(error.message)
                this.$store.commit('error', 'Ha ocurrido un error cambiando el estado del este ciclo de trabajo '+error.message)
               
                return false
            }
            return notificado.res
            
        },

        async fin_del_proceso(carpeta_publica) {
            let url;
            let urls = [];

            console.log(this.parametrosRuta);
            console.log(carpeta_publica)
            for (let index = 0; index < carpeta_publica.length; index++) {
                
                if(carpeta_publica[index].tipo!='Formulario'){
                    try {
                        url = JSON.parse(carpeta_publica[index])
                    } catch (error) {
                        url = carpeta_publica[index]
                    }
    
                    urls.push({
                        link: 'https://drive.educandote.co/audidoc/' + url.token + '/' + url.nombre + '.' + url.tipo,
                        name: url.nombre
                    })
                }
               
            }
            console.log(urls)

            let aux = ''
            for (let index = 0; index < urls.length; index++) {
                aux = aux +
                `<div>
                    <a href="${urls[index].link}" target="_blank"> ${urls[index].name} </a>
                 </div>`
            }
            let correo = `
                <div> 
                    <div> 
                    <header> 
                        Respuesta caso:`+ this.parametrosRuta.data.nombre_documento + ` 
                    </header>   
                    
                    </div>
                    <div>
                            `+ aux + `
                    </div>
                    <div>
                        <p> Tipo documental: `+ this.parametrosRuta.data.tipo_documental + `
                    </div>
                    <div>
                        <p> Comentarios: `+ this.comentario + `
                    </div>
                
                </div>    
            
            `
          
            try {
                let body = {"operation":"sendMail","key":"7c234d3ef69483e","token":"ad140c41a20cb6c",
                "para": this.parametrosRuta.data.correo_de_contacto,"asunto": this.parametrosRuta.data.nombre_documento+' '+ this.parametrosRuta.data.tipo_documental +' '+ new Date()  ,"mensaje":correo}
                var response = await axios.post("https://audidoc.educandote.co/mailjet/enviar_correo.php",
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json ",    
                        },
                    })

                console.log(response)
                let workflow = [...this.parametrosRuta.data.workflow];
                console.log(workflow)

                /*
                for (let index = 0; index < workflow.length; index++) {
                   workflow[index] = JSON.stringify(workflow[index])
                }
                */

                let obj_workflow = {
                    workflow: workflow
                };

                obj_workflow = JSON.stringify(obj_workflow);

                //prueba01almacen_casos_finalizados
                /*
                        fecha_inicio: this.parametrosRuta.data.fecha_asignacion_int,
                        fecha_fin: Date.parse(new Date()),
                        datos_adjuntos: carpeta_publica,
                        workflow: workflow,
                        token: this.parametrosRuta.data.token,
                        solicitante: this.parametrosRuta.data.correo_de_contacto,
                        tipo_documental: this.parametrosRuta.data.tipo_documental,
                        fecha_int: Date.parse(new Date())
                
                */

                let obj = {
                    token: this.parametrosRuta.data.token,
                    tipo_documental: this.parametrosRuta.data.tipo_documental,
                    workflow: obj_workflow,
                    fecha_asignacion_caso: this.parametrosRuta.data.fecha_asignacion_caso,
                    fecha_asignacion_caso_int: this.parametrosRuta.data.fecha_asignacion_int,
                    datos_personales_remitente: ` ${this.parametrosRuta.data.datos_personales_remitente.nombre} ${this.parametrosRuta.data.datos_personales_remitente.tipo_documento} ${this.parametrosRuta.data.datos_personales_remitente.numero_documento}`,
                    empresa_remitente: this.parametrosRuta.data.datos_personales_remitente.empresa,
                    datos_de_contacto_remitente: `${this.parametrosRuta.data.datos_personales_remitente.telefono} ${this.parametrosRuta.data.datos_personales_remitente.correo} ${this.parametrosRuta.data.datos_personales_remitente.direccion_respuesta}` ,
                    asunto: this.parametrosRuta.data.datos_del_radicado.informacion.asunto,
                    descripcion: this.parametrosRuta.data.datos_del_radicado.informacion.descripcion,
                    folios: this.parametrosRuta.data.datos_del_radicado.informacion.folios,
                    anexos: this.parametrosRuta.data.datos_del_radicado.informacion.anexos,    
                };

                obj.fecha_creacion_int = Date.parse( new Date ());
                obj.order = Date.parse( new Date ());
                obj.estatus = 'HABILITADA';
                obj.extended_properties = '{}';

                const nombre_endpoint_almacenamiento = "V1_RadicadosFinalizados"
                let busqueda = await this.$store.dispatch('llamado_post',{
                    url: `https://docutest.educandote.co/types/collections/${nombre_endpoint_almacenamiento}/documents`,
                    body: obj,
                    tipo_header: "indexador"
                });

                console.log(busqueda);

                const carpeta = await this.$store.dispatch('llamado_post',{
                    url:`https://docutest.educandote.co/types/collections/CARPETAS_${nombre_endpoint_almacenamiento}/documents`,
                    body: {
                      "nombre_carpeta": "Carpeta 1",
                      "id_carpeta": "1",
                      "pertenece_a": busqueda.data.id.toString(),
                      "estado_carpeta": "ACTIVO",
                      "fecha_creacion_int": Date.parse(new Date()),
                      "order": Date.parse(new Date()),
                      "extended_properties": JSON.stringify({})                 
                    },
                    tipo_header: "indexador"
                  });

                  let body_index = "";
                    for (let index = 0; index < carpeta_publica.length; index++) {
                        let info_file = null;
                        try {
                            info_file = JSON.parse(carpeta_publica[index]);
                        } catch (error) {
                            info_file = carpeta_publica[index];
                        }
                        
                        body_index = body_index + `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${info_file.autor}", "estado_imagen": "${'habilitada'}", "meta_datos": "", "nombre": "${info_file.nombre}", "nombre_sector": "${info_file.nombre_sector}", "sector": "${info_file.sector}", "tipo": "${info_file.tipo}", "token": "${info_file.token}", "fecha_creacion_int": ${info_file.fecha_adjuntado}, "order": ${info_file.fecha_adjuntado}, "extended_properties": "{}"}\n`
                    }
                    
                    const imagenes = await this.$store.dispatch('llamado_post',{
                        url:`https://docutest.educandote.co/types/collections/IMAGENES_${nombre_endpoint_almacenamiento}/documents/import?action=create`,
                        body: body_index,
                        tipo_header: "indexador"
                    });

                    console.log(imagenes);

                
                
                return {res: true, mensaje: 'Envio de informacion al correo de contacto de manera exitosa' }
            } catch (error) {
                console.log(error)
                return {res: false, mensaje: 'Error enviando la informacion al correo de contacto '+error.message }
            }
        },
    },
}

export default acciones_casos_supervisor